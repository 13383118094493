import React, { useState, useEffect } from 'react';
import { Sparkles } from 'lucide-react';

const NewYear2025Modal = ({ onClose }) => {
  const [balloons, setBalloons] = useState([]);
  const [poppedBalloons, setPoppedBalloons] = useState(new Set());
  const [fireworks, setFireworks] = useState([]);

  // Initialize balloons with adjusted positioning
  useEffect(() => {
    const balloonColors = ["#FF6B6B", "#4ECDC4", "#FFD93D", "#95E1D3", "#A8E6CF"];
    const generatedBalloons = Array.from({ length: 6 }).map((_, index) => ({
      id: index,
      color: balloonColors[Math.floor(Math.random() * balloonColors.length)],
      // Adjust spread to keep balloons inside the modal
      left: `${15 + (index * 13)}%`,
      delay: index * 0.3,
    }));
    setBalloons(generatedBalloons);
  }, []);

  // Fireworks effect
  useEffect(() => {
    const createFirework = () => ({
      id: Math.random(),
      left: `${Math.random() * 100}%`,
      top: `${30 + Math.random() * 40}%`,
      size: 4 + Math.random() * 4,
      color: `hsl(${Math.random() * 360}, 70%, 50%)`
    });

    const initialFireworks = Array.from({ length: 5 }, createFirework);
    setFireworks(initialFireworks);

    const interval = setInterval(() => {
      setFireworks(prev => {
        const newFireworks = prev.slice(1);
        newFireworks.push(createFirework());
        return newFireworks;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleBalloonPop = (id) => {
    if (!poppedBalloons.has(id)) {
      setPoppedBalloons(new Set([...poppedBalloons, id]));
    }
  };

  return (
    <div style={{
      position: 'fixed',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      zIndex: 50
    }}>
      <div style={{
        backgroundColor: 'white',
        borderRadius: '0.75rem',
        boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1)',
        maxWidth: '32rem',
        width: '100%',
        position: 'relative',
        padding: '1.5rem',
        overflow: 'hidden'
      }}>
        {/* Decorative top section for balloons */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '120px',
          backgroundColor: '#F8FAFC',
          borderTopLeftRadius: '0.75rem',
          borderTopRightRadius: '0.75rem',
          overflow: 'hidden',
          zIndex: 1
        }}>
          {/* Balloons Container */}
          <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            paddingTop: '20px'
          }}>
            {balloons.map((balloon) => (
              <div
                key={balloon.id}
                onClick={() => handleBalloonPop(balloon.id)}
                style={{
                  position: 'absolute',
                  left: balloon.left,
                  top: '20px',
                  transition: 'all 0.3s ease',
                  transform: poppedBalloons.has(balloon.id) 
                    ? 'scale(1.5) translateY(-20px)' 
                    : `translateY(${Math.sin(Date.now() / 1000 + balloon.delay) * 10}px)`,
                  opacity: poppedBalloons.has(balloon.id) ? 0 : 1,
                  pointerEvents: 'auto',
                  cursor: 'pointer',
                  animation: `float-balloon ${2 + balloon.delay}s infinite ease-in-out`
                }}
              >
                <div style={{
                  width: '32px', // Reduced balloon size
                  height: '48px', // Reduced balloon size
                  backgroundColor: balloon.color,
                  borderRadius: '50%',
                  position: 'relative'
                }}>
                  <div style={{
                    position: 'absolute',
                    bottom: '-20px', // Shorter string
                    left: '50%',
                    width: '1px',
                    height: '20px', // Shorter string
                    backgroundColor: '#9CA3AF',
                    transform: 'translateX(-50%)'
                  }} />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Fireworks */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          zIndex: 2,
          overflow: 'hidden'
        }}>
          {fireworks.map((firework) => (
            <div
              key={firework.id}
              style={{
                position: 'absolute',
                left: firework.left,
                top: firework.top,
                width: `${firework.size}px`,
                height: `${firework.size}px`,
                backgroundColor: firework.color,
                borderRadius: '50%',
                animation: 'firework 2s ease-out forwards'
              }}
            />
          ))}
        </div>

        {/* Content */}
        <div style={{ position: 'relative', zIndex: 3, marginTop: '60px' }}>
          <button 
            onClick={onClose}
            style={{
              position: 'absolute',
              right: '0',
              top: '-50px',
              fontSize: '1.5rem',
              color: '#6B7280',
              cursor: 'pointer',
              background: 'none',
              border: 'none',
              padding: '0.5rem'
            }}
          >
            ✕
          </button>

          <div style={{
            textAlign: 'center',
            marginBottom: '1.5rem'
          }}>
            <h2 style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#1F2937',
              marginBottom: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5rem'
            }}>
              Happy New Year 2025!
              <Sparkles style={{ color: '#FBBF24' }} size={24} />
            </h2>
            <p style={{
              fontSize: '1.25rem',
              color: '#2563EB',
              fontWeight: '600',
              animation: 'pulse 2s infinite'
            }}>
              Celebrate with 50% OFF Delivery!
            </p>
          </div>

          {/* Rest of content remains the same */}
          <div style={{ marginBottom: '1.5rem' }}>
            <p style={{
              fontSize: '1.125rem',
              color: '#4B5563',
              textAlign: 'center',
              marginBottom: '1.5rem'
            }}>
              Ring in the New Year with special savings on all your favorite treats!
            </p>

            <div style={{
              backgroundColor: '#EFF6FF',
              padding: '1rem',
              borderRadius: '0.5rem',
              border: '1px solid #DBEAFE'
            }}>
              <h3 style={{
                fontWeight: '600',
                color: '#1F2937',
                marginBottom: '0.5rem'
              }}>
                Terms & Conditions:
              </h3>
              <ul style={{ color: '#4B5563' }}>
                {[
                  'Valid from January 1st - January 31st, 2025',
                  '50% off all delivery charges',
                  'Minimum order value: $30',
                  'Valid for all menu items',
                  'Cannot be combined with other offers',
                  'Delivery within service area only'
                ].map((term, index) => (
                  <li key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                    marginBottom: '0.5rem',
                    fontSize: '0.875rem'
                  }}>
                    <span style={{ color: '#2563EB' }}>•</span>
                    {term}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <button
            onClick={() => window.location.href = '/ourcakes'}
            style={{
              width: '100%',
              backgroundColor: '#2563EB',
              color: 'white',
              fontWeight: 'bold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              border: 'none',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              marginTop: '1.5rem'
            }}
            onMouseOver={e => e.target.style.backgroundColor = '#1D4ED8'}
            onMouseOut={e => e.target.style.backgroundColor = '#2563EB'}
          >
            Order Now & Save
          </button>
        </div>

        <style>
          {`
            @keyframes float-balloon {
              0%, 100% { transform: translateY(0); }
              50% { transform: translateY(-10px); }
            }
            @keyframes pulse {
              0%, 100% { opacity: 1; }
              50% { opacity: 0.8; }
            }
            @keyframes firework {
              0% {
                transform: scale(1);
                opacity: 1;
              }
              50% {
                transform: scale(20);
                opacity: 0.5;
              }
              100% {
                transform: scale(30);
                opacity: 0;
              }
            }
          `}
        </style>
      </div>
    </div>
  );
};

export default NewYear2025Modal;