import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { saveShippingInfo } from '../../redux/features/cartSlice';
import { Link, useNavigate } from 'react-router-dom';
import MetaData from "../layout/MetaData";
import CheckoutSteps from './CheckoutSteps';
import "../test/style.css";
import { useCheckOrderCountQuery } from "../../redux/api/orderApi";
import { initGA } from '../analytics';

const cityAddressMapping = {
    "Abbotsford Store": { address: "2610 Cedar Park Pl, Abbotsford, BC", postalCode: "V2T 3S5" },
    "Surrey Store": { address: "8028 128 St Unit 104, Surrey, BC", postalCode: "V3W 4E9" }
};

const generateTimeOptions = () => {
    const options = [];
    for (let hour = 10; hour <= 17; hour++) {
        const time = `${hour.toString().padStart(2, '0')}:00`;
        options.push(<option key={time} value={time}>{time}</option>);
    }
    return options;
};

const Shipping = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [country, setCountry] = useState("Canada");
    const [deliveryDate, setDeliveryDate] = useState('');
    const [time, setTime] = useState('10:00');
    const [agree, setAgree] = useState(false);
    const [dateError, setDateError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [formType, setFormType] = useState('delivery');
    const [deliveryDateAvailable, setDeliveryDateAvailable] = useState(true);

    const { shippingInfo } = useSelector((state) => state.cart);

    const currentDate = new Date();
    const minDeliveryDate = new Date(currentDate);
    minDeliveryDate.setDate(minDeliveryDate.getDate() + 1);
    const minDateString = minDeliveryDate.toISOString().slice(0, 10);

    const { data: orderCountData, error: orderCountError } = useCheckOrderCountQuery(deliveryDate);

    useEffect(() => {
        initGA();
      }, []);

    useEffect(() => {
        if (shippingInfo) {
            setAddress(shippingInfo.address || "");
            setCity(shippingInfo.city || "");
            setPostalCode(shippingInfo.postalCode || "");
            setPhoneNo(shippingInfo.phoneNo || "");
            setCountry(shippingInfo.country || "Canada");
            setDeliveryDate(shippingInfo.deliveryDate || "");
            if (formType === 'pickUp') {
                setTime(shippingInfo.time || "10:00");
            }
        }
    }, [shippingInfo, formType]);

    useEffect(() => {
        if (deliveryDate) {
            if (orderCountError) {
                console.error("Error checking order count:", orderCountError);
                setDeliveryDateAvailable(false);
            } else {
                setDeliveryDateAvailable(true);
            }
        }
    }, [deliveryDate, orderCountError]);

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        setCity(selectedCity);

        if (formType === 'pickUp') {
            const cityInfo = cityAddressMapping[selectedCity];
            if (cityInfo) {
                setAddress(cityInfo.address);
                setPostalCode(cityInfo.postalCode);
            } else {
                setAddress("");
                setPostalCode("");
            }
        }
    };

    const validatePhoneNo = (phone) => {
        const phoneRegex = /^(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4})$/;
        return phoneRegex.test(phone);
    };

    const validatePostalCode = (postalCode) => {
        const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        return postalCodeRegex.test(postalCode);
    };

    const handleModalAgree = () => {
        setAgree(true);
        setShowModal(false);
        submitHandler();
    };

    const handleModalDisagree = () => {
        setShowModal(false);
    };

    const submitHandler = (e) => {
        if (e) e.preventDefault();

        if (!agree) {
            setShowModal(true);
            return;
        }

        // Debug logging
        console.log("Form type:", formType);
        console.log("Address:", address);
        console.log("City:", city);
        console.log("Postal Code:", postalCode);
        console.log("Phone:", phoneNo);
        console.log("Country:", country);
        console.log("Delivery Date:", deliveryDate);
        console.log("Time:", time);

        if (formType === 'delivery' && (!address || !city || !postalCode || !phoneNo || !country || !deliveryDate)) {
            console.log("Missing fields in delivery form");
            alert("Please fill out all required fields.");
            return;
        }

        if (formType === 'pickUp' && (!phoneNo || !city || !deliveryDate || !time || !address || !postalCode)) {
            console.log("Missing fields in pick up form");
            alert("Please fill out all required fields.");
            return;
        }

        if (formType === 'delivery' && !validatePhoneNo(phoneNo)) {
            alert("Please enter a valid Canadian phone number.");
            return;
        }

        if (formType === 'delivery' && !validatePostalCode(postalCode)) {
            alert("Please enter a valid Canadian postal code.");
            return;
        }

        if (new Date(deliveryDate) < minDeliveryDate) {
            setDateError("Delivery/Pick-up date cannot be within 1 day.");
            return;
        } else {
            setDateError("");
        }

        if (!deliveryDateAvailable) {
            alert("We have reached our order limit for the selected day. Please choose another date.");
            return;
        }

        dispatch(saveShippingInfo({
            address,
            city,
            phoneNo,
            postalCode,
            country,
            deliveryDate,
            time: formType === 'pickUp' ? time : undefined
        }));
        navigate('/confirm_order');
    };

    return (
        <>
            <MetaData title={"Shipping Info"} />
            <CheckoutSteps shipping />
            <div className="row wrapper mb-5">
                <div className="col-10 col-lg-5">
                    <form
                        className="shadow rounded bg-body"
                        onSubmit={submitHandler}
                    >
                        <h2 className="mb-4">Shipping Info</h2>

                        <div className="mb-3">
                            <label className="form-label">Select Form Type</label>
                            <div>
                                <button
                                    type="button"
                                    className="btn me-2"
                                    style={{
                                        backgroundColor: formType === 'delivery' ? '#F2C4DA' : 'initial',
                                        color: formType === 'delivery' ? 'black' : 'initial',
                                        border: formType === 'delivery' ? '1px solid #F2C4DA' : '1px solid #ccc'
                                    }}
                                    onClick={() => setFormType('delivery')}
                                >
                                    Delivery
                                </button>
                                <button
                                    type="button"
                                    className="btn"
                                    style={{
                                        backgroundColor: formType === 'pickUp' ? '#F2C4DA' : 'initial',
                                        color: formType === 'pickUp' ? 'black' : 'initial',
                                        border: formType === 'pickUp' ? '1px solid #F2C4DA' : '1px solid #ccc'
                                    }}
                                    onClick={() => setFormType('pickUp')}
                                >
                                    Pick Up
                                </button>
                            </div>
                        </div>

                        {formType === 'delivery' && (
                            <>
                                <div className="mb-3">
    <label htmlFor="address_field" className="form-label">Address</label>
    <div className="d-flex gap-2">
    <input
            type="text"
            id="address_field_part2"
            className="form-control"
            style={{ width: '30%' }}
            value={address.split(' ')[1] || ''} // Takes second part
            onChange={(e) => {
                const part1 = address.split(' ')[0] || '';
                setAddress(`${part1} ${e.target.value}`);
            }}
            placeholder="Apt/House#"
        />
        <input
            type="text"
            id="address_field_part1"
            className="form-control"
            value={address.split(' ')[0] || ''} // Takes first part
            onChange={(e) => {
                const part2 = address.split(' ')[1] || '';
                setAddress(`${e.target.value} ${part2}`);
            }}
            required
            placeholder="Street Address"
        />
        
    </div>
</div>

                                <div className="mb-3">
                                    <label htmlFor="city_field" className="form-label">City</label>
                                    <select
                                        id="city_field"
                                        className="form-select"
                                        name="city"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        required
                                    >
                                        <option value="">Select City</option>
                                        <option value="Surrey, British Columbia">Surrey, British Columbia</option>
                                        <option value="Burnaby, British Columbia">Burnaby, British Columbia</option>
                                        <option value="Maple Ridge, British Columbia">Maple Ridge, British Columbia</option>
                                        <option value="Port Coquitlam">Port Coquitlam</option>
                                        <option value="Richmond, British Columbia">Richmond, British Columbia</option>
                                        <option value="White Rock, British Columbia">White Rock, British Columbia</option>
                                        <option value="Langley, British Columbia">Langley, British Columbia</option>
                                        <option value="Walnut Grove">Walnut Grove</option>
                                        <option value="Coquitlam">Coquitlam</option>
                                        <option value="Delta, British Columbia">Delta, British Columbia</option>
                                        <option value="New Westminster">New Westminster</option>
                                        <option value="Port Moody">Port Moody</option>
                                        <option value="Vancouver">Vancouver</option>
                                        <option value="Ladner, British Columbia">Ladner, British Columbia</option>
                                        <option value="Tsawwassen, British Columbia">Tsawwassen, British Columbia</option>
                                        <option value="West Vancouver">West Vancouver</option>
                                        <option value="East Vancouver">East Vancouver</option>
                                        <option value="North Vancouver">North Vancouver</option>
                                        <option value="Abbotsford, British Columbia">Abbotsford, British Columbia</option>
                                        <option value="Downtown Vancouver">Downtown Vancouver</option>
                                        <option value="Pitt Meadows">Pitt Meadows</option>
                                        <option value="Mission, British Columbia">Mission, British Columbia</option>
                                        <option value="Aldergrove, County Antrim">Aldergrove, County Antrim</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="postalCode_field" className="form-label">Postal Code</label>
                                    <input
                                        type="text"
                                        id="postalCode_field"
                                        className="form-control"
                                        name="postalCode"
                                        value={postalCode}
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="phoneNo_field" className="form-label">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phoneNo_field"
                                        className="form-control"
                                        name="phoneNo"
                                        value={phoneNo}
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="deliveryDate_field" className="form-label">Delivery Date</label>
                                    <input
                                        type="date"
                                        id="deliveryDate_field"
                                        className="form-control"
                                        name="deliveryDate"
                                        value={deliveryDate}
                                        onChange={(e) => setDeliveryDate(e.target.value)}
                                        min={minDateString}
                                        required
                                    />
                                    {dateError && <div className="alert alert-danger">{dateError}</div>}
                                </div>

                                <input
                                    type="hidden"
                                    id="country_field"
                                    name="country"
                                    value={country}
                                />
                            </>
                        )}

                        {formType === 'pickUp' && (
                            <>
                                <div className="mb-3">
                                    <label htmlFor="city_field" className="form-label">Pick Up Location</label>
                                    <select
                                        id="city_field"
                                        className="form-select"
                                        name="city"
                                        value={city}
                                        onChange={handleCityChange}
                                        required
                                    >
                                        <option value="">Select City</option>
                                        {Object.keys(cityAddressMapping).map((cityKey) => (
                                            <option key={cityKey} value={cityKey}>
                                                {cityKey}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="address_field" className="form-label">Pick Up Address</label>
                                    <input
                                        type="text"
                                        id="address_field"
                                        className="form-control"
                                        name="address"
                                        value={address}
                                        disabled
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="postalCode_field" className="form-label">Postal Code</label>
                                    <input
                                        type="text"
                                        id="postalCode_field"
                                        className="form-control"
                                        name="postalCode"
                                        value={postalCode}
                                        disabled
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="phoneNo_field" className="form-label">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phoneNo_field"
                                        className="form-control"
                                        name="phoneNo"
                                        value={phoneNo}
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="deliveryDate_field" className="form-label">Pick Up Date</label>
                                    <input
                                        type="date"
                                        id="deliveryDate_field"
                                        className="form-control"
                                        name="deliveryDate"
                                        value={deliveryDate}
                                        onChange={(e) => setDeliveryDate(e.target.value)}
                                        min={minDateString}
                                        required
                                    />
                                    {dateError && <div className="alert alert-danger">{dateError}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="time_field" className="form-label">Pick Up Time</label>
                                    <select
                                        id="time_field"
                                        className="form-select"
                                        name="time"
                                        value={time}
                                        onChange={(e) => setTime(e.target.value)}
                                        required
                                    >
                                        {generateTimeOptions()}
                                    </select>
                                </div>

                                <input
                                    type="hidden"
                                    id="country_field"
                                    name="country"
                                    value={country}
                                />
                            </>
                        )}

                        <button
                            id="shipping_btn"
                            type="submit"
                            className="btn w-100 py-2"
                            style={{
                                backgroundColor: "#F2C4DA",
                                color: "black",
                                border: "none",
                            }}
                        >
                            CONTINUE
                        </button>
                    </form>
                </div>
            </div>

            {showModal && (
                <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Terms and Conditions</h5>
                                <button type="button" className="btn-close" onClick={handleModalDisagree}></button>
                            </div>
                            <div className="modal-body">
                            You agree to the terms and conditions before proceeding.
                                 <br />
                                1. Delivery will be made within business day 7AM till 7PM Daily.<br />
                                2. <Link to="/delivery">Delivery charges apply based on the location.</Link><br />
                                3. Orders cannot be modified once placed.<br />
                                4.Before placing your order and selecting a delivery date, please confirm that the recipient will be available to accept the delivery. We cannot assume responsibility for orders left unattended.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleModalDisagree}>Disagree</button>
                                <button type="button" className="btn btn-primary" onClick={handleModalAgree}>Agree</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Shipping;
                                