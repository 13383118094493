import React, { useEffect, useState } from "react";
import "./Home.css";
import { useGetProductQuery } from "../redux/api/productsApi";
import {   useFormorderMutation } from "../redux/api/orderApi"; // Corrected import for order API
import Loader from "./layout/Loader";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast"; // Import toast for notifications
import { useDispatch } from "react-redux";
import { clearCart } from "../redux/features/cartSlice";
import NewYearPromoModal from "./PromoModal"; // Import the PromoModal
import { initGA } from './analytics';
const Home = () => {
  const navigate = useNavigate(); // Hook for programmatic navigation
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();
  const { data, isLoading, error } = useGetProductQuery({});
  const [createOrder, { isLoading: isOrdering, error: orderError, isSuccess }] =   useFormorderMutation(); // Order mutation
  const [showPromo, setShowPromo] = useState(true);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    phone: '',
    date: '',
    time: '',
    flavour: '',
    servings: '' // Changed from noofppl to servings
  });

  useEffect(() => {
    initGA();
  }, []);

  // In your home page component
  useEffect(() => {
    // Check if we're arriving from a successful order
    const searchParams = new URLSearchParams(window.location.search);
    const orderSuccess = searchParams.get('order_success');
    
    if (orderSuccess === 'true') {
      dispatch(clearCart());
      toast.success("Purchase successful! Receipt has been sent to your email.", {
        duration: 5000, // 5 seconds in milliseconds
        style: {
          fontSize: '16px', // Making text a bit larger
          padding: '16px', // Adding more padding
        },
      });
      // Optionally, clean up the URL
      window.history.replaceState({}, '', '/home');
    }
  }, [dispatch]);

  const items = [
    // Carousel items
    {
      image: "../images/aman1.jpg",
      title: "Cakes",
      subtitle: "",
      
    },
    {
      image: "../images/aman2.jpg",
      title: "Chocolate Cake",
      subtitle: "",
      
    },
    {
      image: "../images/58.jpg",
      title: "Pastry's",
      subtitle: "",
      
    },
    {
      image: "../images/d12.jpg",
      title: "Donuts",
      subtitle: "",
      
    },
    {
      image: "../images/gg44.jpg",
      title: "Cupcakes",
      subtitle: "",
      
    },
    {
      image: "../images/gg3.jpg",
      title: "Muffins",
      subtitle: "",
     
    },
    // Add more items as needed...
  ];

  // Helper function to ensure HTTPS URLs
    const ensureHttps = (url) => {
        if (typeof url !== 'string') return url;
        return url.replace(/^http:\/\//i, 'https://');
    };
  const updateContent = (index) => {
    const item = items[index];
    const backgroundImage = document.getElementById("background-image");
    const titleElement = document.getElementById("title");
    const subtitleElement = document.getElementById("subtitle");
    
    if (backgroundImage && titleElement && subtitleElement) {
      backgroundImage.style.backgroundImage = `url(${item.image})`;
      titleElement.textContent = item.title;
      subtitleElement.textContent = item.subtitle;
    }
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : items.length - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < items.length - 1 ? prevIndex + 1 : 0
    );
  };

  // Auto-slide effect
  useEffect(() => {
    const autoSlide = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(autoSlide);
  }, []);

  // Update content effect
  useEffect(() => {
    updateContent(currentIndex);
  }, [currentIndex]);

  // Process products for menu section
  const products = Array.isArray(data) ? data : data?.products || [];

  
  // Handle form field changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Validate form fields
  const validateForm = () => {
    // Name validation
    if (formData.name.trim() === '') {
      toast.error('Please enter your name.');
      return false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address.');
      return false;
    }

    // Address validation
    if (formData.address.trim() === '') {
      toast.error('Please enter your address.');
      return false;
    }

    // Phone validation
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone.replace(/\D/g, ''))) {
      toast.error('Please enter a valid 10-digit phone number.');
      return false;
    }

    // Date validation
    const selectedDate = new Date(formData.date);
    const today = new Date();
    if (selectedDate < today) {
      toast.error('Please select a date in the future.');
      return false;
    }
    if (!formData.time) {
      toast.error('Please enter a time.');
      return false;
    }
    // Flavour validation
    if (formData.flavour.trim() === '') {
      toast.error('Please enter the cake flavour.');
      return false;
    }

    // Servings validation
    if (formData.servings <= 0 || formData.servings % 0.5 !== 0) {
      toast.error('Please enter a valid number of servings.');
      return false;
    }

    return true;
  };

  // Handle form submission
  const submitHandler = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Form Data Submitted:", formData);
      createOrder(formData);
    }
  };

  // Handle success and error messages
  useEffect(() => {
    if (orderError) {
      toast.error(orderError?.data?.message);
    }
    if (isSuccess) {
      toast.success("Order placed successfully!");
      navigate("/home");
    }
  }, [orderError, isSuccess, navigate]);

  if (isLoading) return <Loader />;
  if (error) return <div>Error loading menu items</div>;

  return (
    <>
     {showPromo && <NewYearPromoModal onClose={() => setShowPromo(false)} />}
      <div id="home" className="home-container">
  <div className="home-background-image" id="background-image"></div>
  <div className="home-content">
    <h2 id="title">Veggie Cakes</h2>
    <h1 id="subtitle"></h1>
    
    {/* Added golden button with inline styles */}
    <button 
      onClick={() => window.location.href = '/ourcakes'}
      style={{
        backgroundColor: '#FFD700',
        padding: '12px 24px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        margin: '20px auto',
        display: 'block',
        transition: 'transform 0.2s',
        boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
      }}
      onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
      onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
    >
      Order Now
    </button>
  </div>
  
  <div className="home-carousel">
    {items.map((item, index) => (
      <div key={index} className="home-carousel-item">
      </div>
    ))}
  </div>
  <div className="home-nav-arrow left" onClick={prevSlide}>
    <i className="fas fa-chevron-left"></i>
  </div>
  <div className="home-nav-arrow right" onClick={nextSlide}>
    <i className="fas fa-chevron-right"></i>
  </div>
</div>

      <div id="about" className="about-section">
        <div className="about-container">
          <div className="image-section">
            <img
              src="../images/aman1.jpg"
              alt="A dark themed cake with flowers and a skull decoration"
              width="350"
              height="500"
            />
            <img
              src="../images/aman2.jpg"
              alt="A close-up of a berry tart with raspberries and blueberries"
              width="350"
              height="500"
            />
          </div>
          <div className="text-section">
            <h2></h2>
            <h3>Beautiful Moments</h3>
            <p> Welcome to Vegetarian Delite Bakery, From the finest ingredients to our crafted recipes, every creation we offer is made with love and care.
Thank you for being a part of our story.  🌱🍰
</p>
          </div>
        </div>
      </div>

      <div className="header">
        <h1>
          <span> Gallery </span>
          <br />
          Our Special Pastries
        </h1>
      </div>
      <div className="gallery">
        <img
          alt="Three cupcakes with pink frosting on a blue plate"
          height="200"
          src="../images/58.jpg"
          width="300"
        />
        <img
          alt="Cake with fruit toppings on a glass stand"
          height="200"
          src="../images/gg1.jpg"
          width="300"
        />
        <img
          alt="Two slices of cake on plates with a vase of yellow flowers"
          height="200"
          src="../images/gg2.jpg"
          width="300"
        />
        <img
          alt="Cake with caramel and fruit toppings on a white plate"
          height="200"
          src="../images/gg3.jpg"
          width="300"
        />
        <img
          alt="Small cake with red topping and a strawberry on a green plate"
          height="200"
          src="../images/gg44.jpg"
          width="300"
        />
        <img
          alt="Cake with strawberries and blueberries on a blue plate"
          height="200"
          src="../images/gg45.jpg"
          width="300"
        />
        <img
          alt="Two cupcakes with chocolate frosting in polka dot wrappers"
          height="200"
          src="../images/gg46.jpg"
          width="300"
        />
        <img
          alt="Slices of berry cheesecake on a blue and white plate"
          height="200"
          src="../images/v1.jpg"
          width="300"
        />
      </div>
      <div id="menu" className="menu-container">
        <div className="menu-header">
          <h1>Specialities</h1>
          <h2>Our Menu</h2>
        </div>

        <div className="menu-grid">
          {products.slice(0, 9).map((product) => ( // Limit to 9 items
            <div
              key={product._id}
              className="menu-item"
              onClick={() => navigate(`/product/${product._id}`)} // Navigate on click
              style={{ cursor: 'pointer' }} // Make the card look clickable
            >
              <img
                src={ensureHttps(product?.images[0]?.url) || '/placeholder-image.jpg'}
                alt={product.name}
                width="80"
                height="80"
              />
              <div className="details">
                <h4>{product.name}</h4>
                <p>{product.description.split(' ').slice(0, 4).join(' ')}...</p>
              </div>
              <div className="price">${product.price}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="cake-order-page">
      <div className="container">
        <div className="header">
          <h1>Orders</h1>
          <h2>Want Us To Call You Back To Confirm Your Order?</h2>
        </div>
        <form onSubmit={submitHandler}>
          <div className="form-group">
            <div>
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="address">Address</label>
              <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="phone">Phone</label>
              <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="date">Date</label>
              <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="time">Time</label>
              <input type="time" id="time" name="time" value={formData.time} onChange={handleChange} required />
            </div>
          </div>
          <div className="form-group">
            <div>
              <label htmlFor="flavour">Cake Flavour</label>
              <input type="text" id="flavour" name="flavour" value={formData.flavour} onChange={handleChange} required />
            </div>
            <div>
              <label htmlFor="servings">Number of people required for servings</label>
              <input type="number" id="servings" name="servings" value={formData.servings} onChange={handleChange} required min="0.5" step="0.5" />
            </div>
          </div>
          <button type="submit" className="btn" disabled={isOrdering}>
            {isOrdering ? "Processing..." : "Order Your Cake"}
          </button>
        </form>
      </div>
    </div>

      
    </>
  );
};

export default Home;