import React, { useState, useEffect } from 'react';
import MetaData from "../layout/MetaData";
import {useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCartItem, removeCartItem } from '../../redux/features/cartSlice';
import toast from 'react-hot-toast';
import { navigate, useNavigate } from 'react-router-dom';
import { initGA } from '../analytics';
const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {cartItems} = useSelector((state) => state.cart);

    useEffect(() => {
      initGA();
    }, []);

    const increaseQty = (item, quantity) => {
       const newQty = quantity + 1
    
          if(newQty > item?.stock) return;
          setItemToCart(item, newQty)
      }
      const decreaseQty = (item, quantity) => {
        const newQty = quantity - 1
    
        if(newQty <= 0) return;
        setItemToCart(item, newQty)
      }
      
      const setItemToCart = (item, newQty) =>{
        const cartItem = {
        product: item?.product,
        name: item?.name,
        price: item?.price,
        image:item?.image,
        flvour: item?.flvour,
        size: item?.size,
        Addnotetocake: item?.Addnotetocake,
        colour: item?.colour,
        recipecode: item?.recipecode,
        quantity: newQty,
        };
        dispatch(setCartItem(cartItem));
      };

      const removeCartItemHandler = (id) =>{
        dispatch(removeCartItem(id))
      }

      const handleCheckout = () => {
        navigate("/shipping");
    };

    return (
        <>
        <MetaData title={"Your Cart"}/>
        {cartItems?.length === 0 ? (
             <h2 className="mt-5">
            Your Cart is Empty
            </h2>

        ):(
        <>
        <h2 className="mt-5">Your Cart: <b>{cartItems?.length}</b></h2>

    <div className="row d-flex justify-content-between">
      <div className="col-12 col-lg-8">
      {cartItems?.map((item)=>(
        <>
        
        <hr />
        <div className="cart-item" data-key="product1">
          <div className="row">
            <div className="col-4 col-lg-3">
              <img
                src={item.image}
                alt="Laptop"
                height="90"
                width="115"
              />
            </div>
            <div className="col-5 col-lg-3">
            <Link to={`/product/${item?.product}`}>{item?.name}</Link>
            </div>
            <div className="col-4 col-lg-2 mt-4 mt-lg-0">
              <p id="card_item_price">${item?.price}</p>
            </div>
            <div className="col-4 col-lg-3 mt-4 mt-lg-0">
              <div className="stockCounter d-inline">
                <span className="btn btn-danger minus" onClick={() => decreaseQty(item, item.quantity)}> - </span>
                <input
                  type="number"
                  className="form-control count d-inline"
                  value={item.quantity}
                  readonly
                />
                <span className="btn btn-primary plus"onClick={() => increaseQty(item, item.quantity)}> + </span>
              </div>
            </div>
            <div className="col-4 col-lg-1 mt-4 mt-lg-0">
              <i id="delete_cart_item" className="fa fa-trash btn btn-danger" 
              onClick={() => removeCartItemHandler(item?.product)}></i>
            </div>
          </div>
        </div>
        <hr />
        </>
      ))}
   
      </div>

      <div className="col-12 col-lg-3 my-4">
        <div id="order_summary">
          <h4>Order Summary</h4>
          <hr />
          <p>Products: <span className="order-summary-values">
            {cartItems?.reduce((acc, item) => acc + item?.quantity, 0)}{" "} (Unit)</span></p>
          <p>Est. total: <span className="order-summary-values">
            ${cartItems?.reduce((acc, item) => acc + item?.quantity * item.price, 0).toFixed(2)}</span></p>
          <hr />
          <button id="checkout_btn" className="btn btn-primary w-100"onClick={handleCheckout}>
            Check out
          </button>
        </div>
      </div>
    </div>
    </>
    )}
    </>
    );
};
export default Cart;