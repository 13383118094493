// src/analytics.js
export const GA_TRACKING_ID = 'G-131WTMLWKP';

// Initialize Google Analytics
export const initGA = () => {
  if (typeof window !== 'undefined') {
    // Add the script tags dynamically
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA_TRACKING_ID}');
    `;
    
    document.head.appendChild(script1);
    document.head.appendChild(script2);
  }
};