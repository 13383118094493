// helpers.js
// Public holidays data
const publicHolidays = [
    new Date("2025-01-01"), // New Year's Day - Wednesday, January 1, 2025
    new Date("2025-02-17"), // Family Day - Monday, February 17, 2025
    new Date("2025-04-18"), // Good Friday - Friday, April 18, 2025
    new Date("2025-05-19"), // Victoria Day - Monday, May 19, 2025
    new Date("2025-07-01"), // Canada Day - Tuesday, July 1, 2025
    new Date("2025-08-04"), // B.C. Day - Monday, August 4, 2025
    new Date("2025-09-01"), // Labour Day - Monday, September 1, 2025
    new Date("2025-09-30"), // National Day for Truth and Reconciliation - Tuesday, September 30, 2025
    new Date("2025-10-13"), // Thanksgiving Day - Monday, October 13, 2025
    new Date("2025-11-11"), // Remembrance Day - Tuesday, November 11, 2025
    new Date("2025-12-25"), // Christmas Day - Thursday, December 25, 2025
    // Add more holidays as needed
];

// Delivery cost data for different cities
const deliveryCosts = {
"Surrey, British Columbia": { monFri: 7.5, sameDay: 10, weekendHoliday: 12.5, weekendSameDay: 15 },
"Burnaby, British Columbia": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"Maple Ridge, British Columbia": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"Port Coquitlam": { monFri: 15, sameDay: 17.5, weekendHoliday: 20, weekendSameDay: 22.5 },
"Richmond, British Columbia": { monFri: 15, sameDay: 17.5, weekendHoliday: 20, weekendSameDay: 22.5 },
"White Rock, British Columbia": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"Langley, British Columbia": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"Walnut Grove": { monFri: 15, sameDay: 17.5, weekendHoliday: 20, weekendSameDay: 22.5 },
"Coquitlam": { monFri: 15, sameDay: 17.5, weekendHoliday: 20, weekendSameDay: 22.5 },
"Delta, British Columbia": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"New Westminster": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"Port Moody": { monFri: 15, sameDay: 17.5, weekendHoliday: 20, weekendSameDay: 22.5 },
"Vancouver": { monFri: 20, sameDay: 22.5, weekendHoliday: 25, weekendSameDay: 27.5 },
"Ladner, British Columbia": { monFri: 15, sameDay: 17.5, weekendHoliday: 20, weekendSameDay: 22.5 },
"West Vancouver": { monFri: 20, sameDay: 22.5, weekendHoliday: 25, weekendSameDay: 27.5 },
"East Vancouver": { monFri: 20, sameDay: 22.5, weekendHoliday: 25, weekendSameDay: 27.5 },
"North Vancouver": { monFri: 20, sameDay: 22.5, weekendHoliday: 25, weekendSameDay: 27.5 },
"Abbotsford, British Columbia": { monFri: 7.5, sameDay: 10, weekendHoliday: 12.5, weekendSameDay: 15 },
"Downtown Vancouver": { monFri: 20, sameDay: 22.5, weekendHoliday: 25, weekendSameDay: 27.5 },
"Pitt Meadows": { monFri: 17.5, sameDay: 20, weekendHoliday: 22.5, weekendSameDay: 25 },
"Mission, British Columbia": { monFri: 20, sameDay: 22.5, weekendHoliday: 25, weekendSameDay: 27.5 },
"Aldergrove, County Antrim": { monFri: 12.5, sameDay: 15, weekendHoliday: 17.5, weekendSameDay: 20 },
"Abbotsford": { monFri: 7.5, sameDay: 10, weekendHoliday: 12.5, weekendSameDay: 15 },
"Surrey": { monFri: 7.5, sameDay: 10, weekendHoliday: 12.5, weekendSameDay: 15 }

    // Add other cities as necessary
};

// Utility function to parse date in YYYY-MM-DD format
const parseDate = (dateString) => {
    const [year, month, day] = dateString.split('-').map(Number);
    // Months are 0-indexed, so subtract 1
    return new Date(year, month - 1, day);
};

// Function to calculate delivery cost
const calculateDeliveryCost = (city, deliveryDate) => {
    // Use utility function to parse date
    const date = parseDate(deliveryDate);
    console.log(`Delivery Date Input: ${deliveryDate}`);
    console.log(`Parsed Date: ${date.toDateString()}`);

    const dayOfWeek = date.getDay(); // 0 for Sunday, 6 for Saturday
    console.log(`Day of Week: ${dayOfWeek}`);

    // Determine if the day is a public holiday
    const isHoliday = publicHolidays.some(holiday =>
        holiday.toDateString() === date.toDateString()
    );
    console.log(`Is Holiday: ${isHoliday}`);

    // Determine the type of day
    let dayType;
    if (isHoliday || dayOfWeek === 0 || dayOfWeek === 6) {
        dayType = 'weekendHoliday'; // Weekend or Holiday
    } else {
        dayType = 'monFri'; // Weekday
    }

    // Check if same-day delivery is applicable
    const now = new Date();
    const isSameDay = now.toDateString() === date.toDateString();
    if (isSameDay) {
        dayType = (dayOfWeek === 0 || dayOfWeek === 6) ? 'weekendSameDay' : 'sameDay';
    }
    console.log(`Is Same Day: ${isSameDay}, Day Type: ${dayType}`);

    // Fetch the delivery cost based on city and day type
    const cityCosts = deliveryCosts[city];
    if (!cityCosts) {
        console.error(`City not found: ${city}`);
        return 0;
    }

    const deliveryCost = cityCosts[dayType];
    if (deliveryCost === undefined) {
        console.error(`Delivery type not found: ${dayType} for city: ${city}`);
        return 0;
    }

    console.log(`City: ${city}, Delivery Cost for ${dayType}: ${deliveryCost}`);
    return deliveryCost;
};

// Function to get query parameters
export const getPriceQueryParams = (searchParams, key, value) => {
    const hasValueInParam = searchParams.has(key);

    if (value && hasValueInParam) {
        searchParams.set(key, value);
    } else if (value) {
        searchParams.append(key, value);
    } else if (hasValueInParam) {
        searchParams.delete(key);
    }
    return searchParams;
};

export const calculateOrderCost = (cartItems, shippingInfo) => {
    const itemsPrice = cartItems?.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
    );

    // Calculate delivery cost
    const deliveryCost = calculateDeliveryCost(shippingInfo?.city, shippingInfo?.deliveryDate);

    // Calculate tax
    const tax = 0; // set to 0 for now, can be updated later

    // Calculate total price
    const totalPrice = (itemsPrice + deliveryCost + (itemsPrice * tax / 100)).toFixed(2);

    return {
        itemsPrice,
        deliveryCost,
        tax,
        totalPrice
    };
};