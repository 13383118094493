import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import { Link } from 'react-router-dom';
import { useDeleteStoreMutation, useGetAllStoresQuery } from "../../redux/api/storeApi";
import AdminLayout from "../layout/AdminLayout";

const ListStores = () => {
    const { data, isLoading, error } = useGetAllStoresQuery();
    const [deleteStore, { error: deleteError, isSuccess, isDeleteLoading }] = useDeleteStoreMutation();
    const [storeData, setStoreData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'UTC'
        }).format(new Date(date));
    };

    const parseTime = (time) => {
        const [hourMinute, modifier] = time.split(' ');
        let [hours, minutes] = hourMinute.split(':').map(Number);
        if (modifier === 'PM' && hours < 12) hours += 12;
        if (modifier === 'AM' && hours === 12) hours = 0;
        return new Date(0, 0, 0, hours, minutes); // Return a Date object for comparison
    };

    // 7-day order tracking
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0); // Set today's time to 00:00:00 in UTC

    const ordersByDay = [];
    for (let i = 0; i < 7; i++) {
        const currentDay = new Date(today.getTime()); // Clone today's date
        currentDay.setUTCDate(today.getUTCDate() + i); // Adjust date in UTC
        currentDay.setUTCHours(0, 0, 0, 0); // Set time to midnight UTC

        const dailyStores = data?.data?.filter(store => {
            const storeDate = new Date(store?.date);
            storeDate.setUTCHours(0, 0, 0, 0); // Normalize store date to UTC midnight

            // Compare normalized UTC dates
            return storeDate.getTime() === currentDay.getTime();
        });

        // Sort dailyStores by time
        if (dailyStores) {
            dailyStores.sort((a, b) => {
                const timeA = parseTime(a.time || '12:00 AM');
                const timeB = parseTime(b.time || '12:00 AM');
                return timeA - timeB;
            });
        }

        // Add the dailyStores to the ordersByDay array or process further
        ordersByDay.push({ date: currentDay, stores: dailyStores });
    }

    // Output or further processing
    console.log(ordersByDay);

    const generatePDF = (stores, date, pickLocation) => {
        const pdfWindow = window.open("", "_blank");
        pdfWindow.document.write(`
            <html>
                <head>
                    <title>Stores for ${formatDate(date)} - ${pickLocation}</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        table, th, td {
                            border: 1px solid black;
                        }
                        th, td {
                            padding: 8px;
                            text-align: left;
                        }
                    </style>
                </head>
                <body>
                    <h1>Stores for ${formatDate(date)} - ${pickLocation}</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Staff Name</th>
                                <th>Store Name</th>
                                <th>Price</th>
                                <th>Address</th>
                                <th>Phone</th>
                                <th>City</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Cake Flavor</th>
                                <th>Cake Size</th>
                                <th>Cake Message And Instruction</th>
                                <th>Pick</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${stores.map(store => `
                                <tr>
                                    <td>${store.staffname || 'N/A'}</td>
                                    <td>${store.name || 'N/A'}</td>
                                    <td>${store.price || 'N/A'}</td>
                                    <td>${store.address || 'N/A'}</td>
                                    <td>${store.phone || 'N/A'}</td>
                                    <td>${store.city || 'N/A'}</td>
                                    <td>${store.date || 'N/A'}</td>
                                    <td>${store.time || 'N/A'}</td>
                                    <td>${store.cakefl || 'N/A'}</td>
                                    <td>${store.cakeno || 'N/A'}</td>
                                    <td>${store.message || 'N/A'}</td>
                                    <td>${store.pick || 'N/A'}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </body>
            </html>
        `);
        pdfWindow.document.close();
        pdfWindow.print();
    };

    const generateBakerPDF = (stores, date, pickLocation) => {
        const pdfWindow = window.open("", "_blank");
        pdfWindow.document.write(`
            <html>
                <head>
                    <title>Stores for ${formatDate(date)} - ${pickLocation}</title>
                    <style>
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        table, th, td {
                            border: 1px solid black;
                        }
                        th, td {
                            padding: 8px;
                            text-align: left;
                        }
                    </style>
                </head>
                <body>
                    <h1>Stores for ${formatDate(date)} - ${pickLocation}</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Staff Name</th>
                                <th>Customer Number</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Cake Flavor</th>
                                <th>Cake Size</th>
                                <th>Cake Message And Instruction</th>
                                <th>Pick</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${stores.map(store => `
                                <tr>
                                    <td>${store.staffname || 'N/A'}</td>
                                    <td>${store.phone || 'N/A'}</td>
                                    <td>${store.date || 'N/A'}</td>
                                    <td>${store.time || 'N/A'}</td>
                                    <td>${store.cakefl || 'N/A'}</td>
                                    <td>${store.cakeno || 'N/A'}</td>
                                    <td>${store.message || 'N/A'}</td>
                                    <td>${store.pick || 'N/A'}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                </body>
            </html>
        `);
        pdfWindow.document.close();
        pdfWindow.print();
    };

    useEffect(() => {
        if (error) {
            toast.error(error?.data?.message);
        }
        if (deleteError) {
            toast.error(deleteError?.data?.message);
        }
        if (isSuccess) {
            toast.success('Store deleted successfully');
        }

        if (data) {
            const rows = data.data?.map((store) => ({
                id: store._id,
                staffName: store.staffname || 'N/A',
                price: store.price || 'N/A',
                name: store.name || 'N/A',
                address: store.address || 'N/A',
                phone: store.phone || 'N/A',
                city: store.city || 'N/A',
                po: store.po || 'N/A',
                date: store.date || 'N/A',
                time: store.time || 'N/A',
                cakefl: store.cakefl || 'N/A',
                cakeno: store.cakeno || 'N/A',
                message: store.message || 'N/A',
                payment: store.payment || 'N/A',
                pick: store.pick || 'N/A',
            })) || [];

            setStoreData(rows);
        }
    }, [data, error, deleteError, isSuccess]);

    const deleteStoreHandler = (id) => {
        deleteStore(id);
    };

    // Search functionality
    const filteredStores = storeData.filter(store =>
        String(store.staffName).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.name).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.address).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.price).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.phone ).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.city).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.po).toLowerCase().includes(searchTerm.toLowerCase()) ||
        String(store.date).toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination functionality
    const totalPages = Math.ceil(filteredStores.length / itemsPerPage);
    const currentStores = filteredStores.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <AdminLayout>
            <div>
                <div className="future-stores-section my-5">
                    <h3>Daily Orders From Stores</h3>
                    {ordersByDay.map(({ date, stores }, index) => {
                        // Filter stores for Surrey
                        const surreyStores = stores.filter(store => store.pick === "Surrey");
                        // Filter stores for Abbotsford
                        const abbotsfordStores = stores.filter(store => store.pick === "Abbotsford");

                        return (
                            <div key={index} className="mb-4">
                                <h4>{formatDate(date)}</h4>
                                
                                {/* Surrey Stores Section */}
                                <div className="mb-4">
                                    <h5>Surrey Stores</h5>
                                    <div className="mb-3">
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={() => generatePDF(surreyStores, date, "Surrey")}
                                            disabled={!surreyStores || surreyStores.length === 0}
                                        >
                                            Download Surrey Stores as PDF
                                        </button>
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={() => generateBakerPDF(surreyStores, date, "Surrey")}
                                            disabled={!surreyStores || surreyStores.length === 0}
                                        >
                                            Download Surrey Store Baker PDF
                                        </button>
                                    </div>

                                    <div className="table-responsive">
                                        {(!surreyStores || surreyStores.length === 0) ? (
                                            <div className="alert alert-info">No Surrey stores for this day.</div>
                                        ) : (
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Staff Name</th>
                                                        <th>Store Name</th>
                                                        <th>Price</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>City</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Cake Flavor</th>
                                                        <th>Cake Size</th>
                                                        <th>Cake Message And Instruction</th>
                                                        <th>Pick</th>
                                                        <th>Update</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
    {surreyStores.map((store) => (
        <tr key={store._id}>
            <td>{store.staffname || 'N/A'}</td>
            <td>{store.name || 'N/A'}</td>
            <td>{store.price || 'N/A'}</td>
            <td>{store.address || 'N/A'}</td>
            <td>{store.phone || 'N/A'}</td>
            <td>{store.city || 'N/A'}</td>
            <td>{store.date || 'N/A'}</td>
            <td>{store.time || 'N/A'}</td>
            <td>{store.cakefl || 'N/A'}</td>
            <td>{store.cakeno || 'N/A'}</td>
            <td>{store.message || 'N/A'}</td>
            <td>{store.pick || 'N/A'}</td>
            <td>
                <Link to={`/store/orderupdate/${store._id}`} className="btn btn-outline-primary me-2">
                    <i className="fa fa-pencil"></i>
                </Link>
            </td>
        </tr>
    ))}
</tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>

                                {/* Abbotsford Stores Section */}
                                <div className="mb-4">
                                    <h5>Abbotsford Stores</h5>
                                    <div className="mb-3">
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={() => generatePDF(abbotsfordStores, date, "Abbotsford")}
                                            disabled={!abbotsfordStores || abbotsfordStores.length === 0}
                                        >
                                            Download Abbotsford Stores as PDF
                                        </button>
                                        <button
                                            className="btn btn-primary mb-3"
                                            onClick={() => generateBakerPDF(abbotsfordStores, date, "Abbotsford")}
                                            disabled={!abbotsfordStores || abbotsfordStores.length === 0}
                                        >
                                            Download Abbotsford Store Baker PDF
                                        </button>
                                    </div>

                                    <div className="table-responsive">
                                        {(!abbotsfordStores || abbotsfordStores.length === 0) ? (
                                            <div className="alert alert-info">No Abbotsford stores for this day.</div>
                                        ) : (
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Staff Name</th>
                                                        <th>Store Name</th>
                                                        <th>Price</th>
                                                        <th>Address</th>
                                                        <th>Phone</th>
                                                        <th>City</th>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        <th>Cake Flavor</th>
                                                        <th>Cake Size</th>
                                                        <th>Cake Message And Instruction</th>
                                                        <th>Pick</th>
                                                        <th>Update</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
    {abbotsfordStores.map((store) => (
        <tr key={store._id}>
            <td>{store.staffname || 'N/A'}</td>
            <td>{store.name || 'N/A'}</td>
            <td>{store.price || 'N/A'}</td>
            <td>{store.address || 'N/A'}</td>
            <td>{store.phone || 'N/A'}</td>
            <td>{store.city || 'N/A'}</td>
            <td>{store.date || 'N/A'}</td>
            <td>{store.time || 'N/A'}</td>
            <td>{store.cakefl || 'N/A'}</td>
            <td>{store.cakeno || 'N/A'}</td>
            <td>{store.message || 'N/A'}</td>
            <td>{store.pick || 'N/A'}</td>
            <td>
                <Link to={`/store/orderupdate/${store._id}`} className="btn btn-outline-primary me-2">
                    <i className="fa fa-pencil"></i>
                </Link>
            </td>
        </tr>
    ))}
</tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <h1 style={{ textAlign: 'center', margin : '20px 0' }}>All Stores</h1>
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ marginBottom: '20px', padding: '8px', width: '100%' }}
                />
                <div style={{ overflowX: 'auto' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '20px 0' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#f2f2f2' }}>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Staff Name</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Price</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Store Name</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Address</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Phone</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>City</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>PO</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Date</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Time</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Cake Flavor</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Cake Size</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Message</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Payment</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Pick</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentStores.length > 0 ? (
                                currentStores.map((store) => (
                                    <tr key={store.id}>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.staffName}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.price}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.name}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.address}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.phone}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.city}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.po}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.date}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.time}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.cakefl}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.cakeno}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.message}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.payment}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>{store.pick}</td>
                                        <td style={{ border: '1px solid black', padding: '8px' }}>
                                            <div className="d-flex align-items-center">
                                                <Link to={`/store/orderupdate/${store.id}`} className="btn btn-outline-primary me-2">
                                                    <i className="fa fa-pencil"></i>
                                                </Link>
                                                <button className="btn btn-outline-danger me-2" onClick={() => deleteStoreHandler(store.id)} disabled={isDeleteLoading}>
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="15" style={{ textAlign: 'center', border: '1px solid black', padding: '8px' }}>
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
    {currentPage > 1 && (
        <button
            onClick={() => handlePageChange(currentPage - 1)}
            style={{
                margin: '0 5px',
                padding: '10px',
                backgroundColor: '#f2f2f2',
                color: '#000',
                border: 'none',
                cursor: 'pointer'
            }}
        >
            Previous
        </button>
    )}
    {Array.from({ length: Math.min(5, totalPages) }, (_, index) => {
        const pageNumber = currentPage <= 3 
            ? index + 1 
            : (currentPage >= totalPages - 2 
                ? totalPages - 4 + index 
                : currentPage - 3 + index);
        
        if (pageNumber > 0 && pageNumber <= totalPages) {
            return (
                <button
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    style={{
                        margin: '0 5px',
                        padding: '10px',
                        backgroundColor: currentPage === pageNumber ? '#007bff' : '#f2f2f2',
                        color: currentPage === pageNumber ? '#fff' : '#000',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    {pageNumber}
                </button>
            );
        }
        return null;
    })}
    {currentPage < totalPages && (
        <button
            onClick={() => handlePageChange(currentPage + 1)}
            style={{
                margin: '0 5px',
                padding: '10px',
                backgroundColor: '#f2f2f2',
                color: '#000',
                border: 'none',
                cursor: 'pointer'
            }}
        >
            Next
        </button>
    )}
</div>
            </div>
        </AdminLayout>
    );
};

export default ListStores;