import React, { useEffect, useState } from "react";
import Loader from "../layout/Loader";
import { toast } from "react-hot-toast";
import MetaData from "../layout/MetaData";
import AdminLayout from "../layout/AdminLayout";
import { useNavigate } from "react-router-dom";
import { PRODUCT_CATEGORIES } from "../../constants/constants";
import { useCreateProductMutation } from "../../redux/api/productsApi";

const NewProductB = () => {
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    name: "",
    price: "",
    sizeSmall: "",
    recipecode: "",
    description: "",
    category: "",
  });

  const [createProduct, { isLoading, error, isSuccess }] = useCreateProductMutation();

  useEffect(() => {
    if (error) {
      toast.error(error.data.message);
    }
    if (isSuccess) {
      toast.success("Product created successfully");
      navigate("/admin/products");
    }
  }, [error, isSuccess, navigate]);

  const onChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    createProduct(product);
  };

  const {
    name,
    price,
    sizeSmall,
    recipecode,
    description,
    category,
  } = product;

  return (
    <AdminLayout>
      <MetaData title={"Create new product"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-10 mt-5 mt-lg-0">
          <form className="shadow rounded bg-body" onSubmit={submitHandler}>
            <h2 className="mb-4">New Bakery Product</h2>

            <div className="mb-3">
              <label htmlFor="name_field" className="form-label">Name</label>
              <input type="text" id="name_field" className="form-control" name="name" value={name} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="description_field" className="form-label">Description</label>
              <textarea className="form-control" id="description_field" rows="8" name="description" value={description} onChange={onChange}></textarea>
            </div>

            <div className="mb-3">
              <label htmlFor="price_field" className="form-label">Price</label>
              <input type="number" id="price_field" className="form-control" name="price" value={price} onChange={onChange} />
            </div>

            <div className="row">
              <div className="mb-3 col">
                <label htmlFor="sizeSmall_field" className="form-label">Price Of Item</label>
                <input type="text" id="sizeSmall_field" className="form-control" name="sizeSmall" value={sizeSmall} onChange={onChange} />
              </div>
             
            </div>


            <div className="mb-3">
              <label htmlFor="recipecode_field" className="form-label">Product Code</label>
              <input type="text" id="recipecode_field" className="form-control" name="recipecode" value={recipecode} onChange={onChange} />
            </div>

            <div className="mb-3">
              <label htmlFor="category_field" className="form-label">Category</label>
              <select id="category_field" className="form-control" name="category" value={category} onChange={onChange}>
                <option value="">Choose Category</option>
                {PRODUCT_CATEGORIES.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <button id="login_button" type="submit" className="btn btn-primary py-2 w-100" disabled={isLoading}>
              {isLoading ? <Loader /> : "CREATE"}
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default NewProductB;